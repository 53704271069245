import React from 'react';
import { connect } from 'react-redux';
import Cookies from 'js-cookie';
import moment from 'moment';
import styled from 'styled-components';

import notificationIconGray from '../assets/images/notification-gray.svg';
import notificationIconWhite from '../assets/images/notification-white.svg';
import { Notification } from '../pages';
import { getAdminsForCurrentLocation, getEquipment, getRecords, getTasks, loadAdmin, loadLibrary, loadUser, openNotificationPanel, openPanel, selectPanel, changeLocation, updateUser, signOut } from '../actions';
import { Spinner } from './';
import Select from 'react-select';
import { DropDownStyle } from '../helpers';

const HeaderComponent = styled.nav`
  position: fixed;
  width: 100%;
  height:78px;
  top: 0;
  bottom: 0;
  z-index: 999;
  background-color: #F3F5F9;
  display: flex;
  @media screen and (max-device-width:4000px) and (orientation: landscape) {
    left:0;
  }
`;

const UserContainer = styled.div`
  width:200px;
  background:${(props) => (props.selected ? 'green' : '#fff')};
  border:1px solid #EFEFEF;
  display: flex;
  justify-content:space-between;
  border-radius:10px;
  padding:0 10px;
  align-items: center;
  cursor:pointer;
  h5{
    color:${(props) => (props.selected ? '#fff' : 'black')};
  }
  @media screen and (max-device-width: 400px) and (orientation: portrait) {
    width:auto;
    .full-name{
      display:none;
    }
  }
`;
const NameBlock = styled.span`
  border: 1px solid green;
  padding:5px;
  border-radius:50%;
  background-color:#E7F3EB;
  color:#4FB973;
  margin-right: 1rem;
  font-size: 11px;
  height: 30px;
  width: 30px;
  text-align: center;
 `;

const UserPanel = styled.div`
    position: absolute;
    top: 85%;
    left: 80px;
    background-color: white;
    width: 200px;
    border: 1px solid #d3d3d3;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
    border-radius: 7px;
    padding: 10px;
    z-index: 10000;
    max-height:60vh;
    overflow-y: auto;
`;

const HeaderContainer = styled.div`
  width: 100%;
  margin: 0 1rem 0 6.5rem !important;
  display: block;
`;

const UserPanelSection = styled.div`
  padding: 10px 20px;
  color: black;
  .practice {
    color: black;
  }
  .location {
    cursor: pointer;
    word-break: break-all;
  }
  .unSelected {
    color: black;
    word-break: break-all;
  }
  .selected {
    font-weight:500;
    word-break: break-all;
    color: var(--key-color);
}`;

const UserPanelName = styled.div`
  color: black;
  font-weight: 600;
  word-break: break-all;
`;

const UserPanelEmail = styled.div`
  color: black;
  word-break: break-all;
`;

const PopupLinks = styled.p`
  color: black;
  line-height: 25px;
  cursor: pointer;
  margin: 0;
`;

const NotificationButton = styled.div`
  background-color:white;
  border-radius:7px;
  padding: 3px;
  cursor:pointer;
  background:${(props) => (props.selected ? 'green' : '#fff')};
`;

const NotificationPanel = styled.div`
    position: absolute;
    top: 85%;
    right: 15px;
    background-color: white;
    width: 300px;
    border: 1px solid #d3d3d3;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
    border-radius: 7px;
    padding: 10px;
    z-index: 10;
    max-height:60vh;
    overflow-y: auto;
`;

const SingleOptionContainer = styled.div`
  background-color: white;
  height: 3rem;
  align-content: center;
  padding: 1rem;
  font-weight: 500;
  display: flex;
  justify-content: center;
  font-size: 1.25rem;
  align-items: center;
  border-radius: 7px;
`;

class Header extends React.Component {
  state = {
  };

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(openPanel(false));
    dispatch(openNotificationPanel(false));
  };

  goToAccount = async () => {
    const { history, dispatch } = this.props;
    await dispatch(openPanel(false));
    await dispatch(openNotificationPanel(false));
    dispatch(selectPanel('details'));
    history.push('/account');
  };

  switchLocation = async (location, practice) => {
    try {
      const { user, dispatch } = this.props;
      if (!location || !location._id || !practice || !practice._id) {
        console.error("Invalid location or practice:", location, practice);
        return;
      }
      await dispatch(changeLocation(location, practice));
      this.setState({ isLoading: true });
      await dispatch(getEquipment(location._id));
      await dispatch(getAdminsForCurrentLocation());
      // store the location and practice indexes in the db
      await dispatch(updateUser({ currentLocationIndex: user.locationIds.indexOf(location._id), currentPracticeIndex: user.practiceIds.indexOf(practice._id) }, false));
      // now retrieve the updated user
      const updatedUser = await dispatch(loadUser(user));
      // depending on which page the user is on, we need to load some data
      if (updatedUser) {
        const { user, dispatch } = this.props;
        switch (window.location.pathname) {
          case '/records':
            await dispatch(getRecords());
            break;
          case '/library':
            await dispatch(loadLibrary());
            break;
          case '/administration':
            await dispatch(loadAdmin());
            break;
          default:
            // await dispatch(getTasks(user.location._id));
            await dispatch(loadAdmin());
        }
      };
      this.setState({ showPanel: !this.state.showPanel });
      // await dispatch(openPanel(false));
      window.location.href = '/';
    } catch (error) {
      console.log('error', error);
    }
  };

  toggleNamePanel = async () => {
    const { dispatch, common } = this.props;
    this.setState({ showSupport: false });
    await dispatch(openPanel(!common.showPanel));
    await dispatch(openNotificationPanel(false));
  };

  signOut = () => {
    const { dispatch } = this.props;
    dispatch(signOut());
  };

  openNotification = async () => {
    const { dispatch, common } = this.props;
    await dispatch(openNotificationPanel(!common.notificationPanel));
    await dispatch(openPanel(false));
  };

  render() {
    if (!Cookies.get('token')) {
      return null;
    } else {
      const { user, common: { showPanel, notificationPanel }, count } = this.props;
      let filteredPractices = [];

      if (user.joinCodeHash && user.joinCodeHash.length > 0) {
        filteredPractices = user.practices.filter(practice =>
          !user.joinCodeHash.some(hash => hash.practiceId === practice._id && hash.status !== 'Accepted')
        );
      }

      // If no accepted practices, use all practices
      if (filteredPractices.length === 0) {
        filteredPractices = user.practices;
      }

      const options = filteredPractices.flatMap((practice) =>
        practice.locations
          .map((locationId) => {
            const location = user.locations.find((loc) => loc._id === locationId);
            return location ? { value: location._id, label: location.name, practiceName: practice.name } : null;
          })
          .filter(Boolean)
      );
      const selectedOption = options.find(option => option.value === user.location._id);
      return (
        <HeaderComponent className="justify-content-between align-items-center">
          {this.state.isLoading && <Spinner />}
          <HeaderContainer className='d-flex justify-content-between'>
            <UserContainer selected={showPanel} onClick={this.toggleNamePanel}>
              <div className='d-flex align-items-center'>
                <NameBlock>{user.firstName[0]}
                  {user.lastName[0]}</NameBlock>
                <h5 className='full-name p-0 mb-0'>{user.fullName}</h5>
              </div>
              <h5 className='fa fa-caret-down align-items-center p-0 mb-0' />
              {showPanel &&
                <UserPanel>
                  <UserPanelSection>
                    <UserPanelName>{user.fullName}</UserPanelName>
                    <UserPanelEmail>{user.email ? user.email : user.phone}</UserPanelEmail>
                  </UserPanelSection>
                  <UserPanelSection>
                    <PopupLinks onClick={this.goToAccount}>My Account</PopupLinks>{' '}
                    <PopupLinks onClick={this.signOut} style={{ "marginTop": "0.9rem" }}>Sign Out</PopupLinks>
                  </UserPanelSection>
                </UserPanel>
              }
            </UserContainer>
            <div style={{ position: "absolute", left: "50%", transform: "translateX(-50%)", }}>
              {options?.length > 1 ? (
                <Select
                  value={selectedOption}
                  options={options}
                  styles={DropDownStyle}
                  getOptionLabel={(option) => {
                    const location = user.locations.find((loc) => loc._id === option.value);
                    const practice = user.practices.find((practice) =>
                      practice.locations.includes(location?._id)
                    );
                    return practice && location ? `${practice.name} - ${location.name}` : location?.name;
                  }}
                  onChange={(selected) => {
                    const selectedLocation = user.locations.find((loc) => loc._id === selected.value);
                    const selectedPractice = user.practices.find((practice) =>
                      practice.locations.includes(selectedLocation._id)
                    );
                    this.switchLocation(selectedLocation, selectedPractice);
                  }}
                />
              ) : (
                <SingleOptionContainer>
                  {options[0]?.practiceName} - {options[0]?.label}
                </SingleOptionContainer>
              )}
            </div>
            <div className='d-flex align-items-center'>
              <h5 className='mb-0 mr-2'> {moment().format('ddd, MMM DD, YYYY')}</h5>
              <NotificationButton selected={notificationPanel}>
                <span className="badge badge-success" style={{ position: "absolute", marginLeft: "18px" }}>{count}</span>
                <img onClick={this.openNotification} width='35' style={{ 'cursor': 'pointer' }} src={!notificationPanel ? notificationIconGray : notificationIconWhite} alt='Notification' />
                {notificationPanel &&
                  <NotificationPanel>
                    <Notification {...this.props} />
                  </NotificationPanel>
                }
              </NotificationButton>

            </div>
          </HeaderContainer>
        </HeaderComponent>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user.currentUser,
    common: state.common,
    count: state.notification.count || 0
  };
};

export default connect(mapStateToProps)(Header);
