import React, { Component } from 'react';
import { Route, Switch, Router } from 'react-router-dom';
import { connect } from 'react-redux';
import { createBrowserHistory } from 'history';
import { toast, Zoom } from 'react-toastify';
import { createClient } from 'contentful';
import styled from 'styled-components';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import GlobalStyle from './globalStyle';
import { Login, NoMatch, Library, UpdateSubscription, Account, Admin, FormsList, FormView, SDSList, AddSDS, ShowSDSRequests, ChatBox, TrainingList, TrainingView, ForgotPassword, RedirectUrlComponent, ResetPassword, JoinWithCode, Dashboard, GroupTraining, RecordList, PracticeRegistration, UserPersonalInfo, LibraryView, GroupTrainingPreparation, GroupTrainingList, GroupTrainingView } from './pages';
import { AdditionalLibrarySetup, AuthRoute, AuthRouteSDS, AuthRedirect, LibrarySetup, AuthRouteOSHA, IEBanner } from './components';

import { openNotificationPanel, openPanel } from './actions';
// import Account from './pages/Account/Account';

export const history = createBrowserHistory();

export const client = createClient({
  // This is the space ID. A space is like a project folder in Contentful terms
  space: 'ba0lcvl3f09j',
  // This is the access token for this space. Normally you get both ID and the token in the Contentful web app
  accessToken: 'e6fb2ca4017154a83c25f278a1ad3f4db46048bbba6ac0017d859c6cea3bacfb'
});

const SiteWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  background-color: #F3F5F9;
  overflow-x: hidden;
  padding-right: 7px;
`;

toast.configure({
  position: 'bottom-right',
  transition: Zoom
});

class App extends Component {
  checkClick = async () => {
    const { dispatch, common: { showPanel, notificationPanel } } = this.props;
    if (showPanel) {
      await dispatch(openPanel(false));
    };
    if (notificationPanel) {
      await dispatch(openNotificationPanel(false));
    };

  };
  render() {
    const { authenticated, user } = this.props;
    return (
      <span onClick={this.checkClick}>
        <Router history={history}>
          <GlobalStyle />
          <IEBanner />
          <SiteWrapper data-testid='appComponent'>
            <Switch>
              <AuthRouteOSHA
                exact
                path='/'
                component={Dashboard}
                authenticated={authenticated}
                user={user}
              />
              <Route component={Login} path='/signin' />
              <AuthRoute
                authenticated={authenticated}
                user={user}
                component={Account}
                path='/account'
              />
              <AuthRouteOSHA
                exact
                authenticated={authenticated}
                user={user}
                component={TrainingList}
                path='/training'
              />
              <AuthRouteOSHA
                exact
                authenticated={authenticated}
                user={user}
                component={GroupTrainingPreparation}
                path='/training/group'
              />
              <AuthRouteOSHA
                exact
                authenticated={authenticated}
                user={user}
                component={GroupTrainingList}
                path='/training/group/list'
              />
              <AuthRouteOSHA
                exact
                authenticated={authenticated}
                user={user}
                component={GroupTrainingView}
                path='/training/group/view'
              />
              <AuthRouteOSHA
                authenticated={authenticated}
                user={user}
                component={TrainingView}
                path='/training/view'
              />
              <AuthRouteOSHA
                authenticated={authenticated}
                user={user}
                component={GroupTraining}
                path='/training/groupTraining'
              />
              <AuthRouteOSHA
                authenticated={authenticated}
                user={user}
                component={RecordList}
                path='/records'
              />
              <AuthRouteOSHA
                exact
                authenticated={authenticated}
                user={user}
                component={Library}
                path='/library'
              />
              <AuthRouteOSHA
                exact
                authenticated={authenticated}
                user={user}
                component={LibraryView}
                path='/library/view'
              />
              <AuthRouteOSHA
                authenticated={authenticated}
                user={user}
                component={AdditionalLibrarySetup}
                path='/library/additionalLibrarySetup'
              />
              <AuthRouteOSHA
                authenticated={authenticated}
                user={user}
                component={LibrarySetup}
                path='/library/setup'
              />
              <AuthRouteOSHA
                authenticated={authenticated}
                user={user}
                component={ChatBox}
                path='/medicc'
              />
              <AuthRouteSDS
                exact
                authenticated={authenticated}
                user={user}
                component={SDSList}
                path='/sds'
              />
              <AuthRouteSDS
                authenticated={authenticated}
                user={user}
                component={AddSDS}
                path='/sds/add'
              />
              <AuthRouteSDS
                authenticated={authenticated}
                user={user}
                component={ShowSDSRequests}
                path='/sds/show-requests'
              />
              <AuthRoute
                // exact
                authenticated={authenticated}
                user={user}
                component={Admin}
                path='/admin'
              />
              <AuthRouteOSHA
                authenticated={authenticated}
                user={user}
                component={FormView}
                path='/forms/view'
              />
              <AuthRouteOSHA
                exact
                authenticated={authenticated}
                user={user}
                component={FormsList}
                path='/forms'
              />
              <AuthRedirect
                authenticated={authenticated}
                user={user}
                component={UpdateSubscription}
                path='/update_subscription'
              />
              <Route
                // exact
                component={RedirectUrlComponent}
                path='/oc'
              />
              <Route component={JoinWithCode} exact path='/register' />
              <AuthRedirect component={PracticeRegistration} path='/registerPractice' />
              <AuthRedirect component={UserPersonalInfo} path='/registerUser' />
              <Route component={Login} path='/onboarding' />
              <Route component={Login} path='/logout' />
              <Route component={ResetPassword} path='/resetPassword' />
              <Route component={ForgotPassword} path='/requestpassword' />
              <Route component={NoMatch} />
            </Switch>
          </SiteWrapper>
        </Router>
      </span>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authenticated: state.user.authenticated,
    user: state.user.currentUser,
    common: state.common
  };
};

export default connect(mapStateToProps)(App);
