import { Input, Button } from '../../components';
import styled from 'styled-components';
import eyeglass from '../../assets/images/glyph-search-gray.svg';
import crossArrow from '../../assets/images/cross-arrow.svg';
import { Card, Col, Figure, Row } from 'react-bootstrap';

export const TableContainer = styled.div`
    overflow-y:auto;
    min-height: 30vh;
    max-height: ${(props) => (props.maxHeight ? props.maxHeight : '61vh')};
`;

export const TableHead = styled.thead`
    background: white;
    top: 0;
    position: sticky;
    th {
          vertical-align: middle !important;
    }
`;

export const WhiteButton = styled(Button)`
  border-radius: 4px;
  text-decoration: none;
  background: #fbfbfb;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14);
  border: 0.5px solid #eaeaea;
  color: #7f808f;
  transition: box-shadow 0.2s;
`;

export const DetailContainer = styled.div`
    overflow-y: auto;
    // max-height:60vh;
`;

export const AddButton = styled.button`
  width: 200px;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background-color: rgba(66, 167, 100, 1);
  box-shadow: 0 1px 1px 0 rgba(0,0,0,0.14);
  border: none;
  border-radius: 5px;
  color: #ffffff;
`;

export const SearchInput = styled(Input)`
  background: url('${eyeglass}') white;
  background-repeat: no-repeat;
  background-position: 10px center;
  padding-left: 30px; /* Adjust the padding as needed */
  @media screen and (max-device-width: 767px) and (orientation: portrait) {
      width:70%;
  }
  @media screen and (max-device-width: 767px) and (orientation: landscape) {
      width:70%;
  }
`;

export const StyledLabel = styled.label`
  font-weight: 600;
  font-size: 11px;
  color: var(--light-text-color);
  line-height: 22px;
  text-transform: uppercase;
  margin: 0;
`;

export const PaginationSection = styled.div`
.active>.page-link, .page-link.active {
  background-color:#4FB973;
  border: none;
  z-index: 0;
  display:list-item;
}
.page-item.active .page-link {
  background-color:#4FB973;
  border: none;
}
.page-link:focus {
  box-shadow: none; 
}
 .nextAndPrev{
  display:list-item !important;
}
.page-link{
  color:#7E818F;
  font-size: 12px !important;
  white-space:nowrap;
  border: none;
}
.page-link:hover{
  color:#08c046;
}
.active>.page-link:hover{
  color:white;
}
`;

export const ToggleButtonContainer = styled.div`
.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745c9;
  border-color: #28a745;
}
`;

export const FilterContainer = styled.span`
    position: relative;
`;

export const OverlayContainer = styled.div`
    position: absolute;
    top: 100%;
    left: 0;
    background-color: white;
    width: 200px;
    border: 1px solid #d3d3d3;
    box-shadow: 0 1px 4px 0 rgb(0 0 0 / 10%);
    border-radius: 7px;
    margin-top: 20px;
    padding: 10px;
    z-index: 10;
`;

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: end;
    margin-top: 0.5rem;
`;

export const CrosseArrowButton = styled.div`
  color: var(--key-color);
  font-weight: 600;
  cursor: pointer;
  &::after {
    content: '';
    display: inline-block;
    width: calc(0.9em - 1px);
    height: calc(0.8em);
    margin-left: 5px;
    background-image: url("${crossArrow}");
    background-size: cover;
    background-repeat: no-repeat;
    transition: margin-left 0.25s ease-out;
  }
  &:hover {
    color: var(--key-color);
    &::after {
      margin-left: 10px; 
    }
  }
  &:focus {
    outline: 0;
    border: none;
    -moz-outline-style: none;
  }
`;

export const CustomCard = styled(Card)`
    cursor: pointer;
`;

export const CardBody = styled(Card.Body)`
    :hover {
        background: #4FB973;
        transition: all 0.2s ease-out;

        .text-success {
            color: #fff !important;
        }

        .figure-caption {
            color: #fff !important;
        }
    }
    .figure-caption{
        h6{
            @media screen and (max-device-width:1024px) and (orientation: portrait) {
                max-width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                word-break: break-word;
            }
            @media screen and (max-device-width:768px) and (orientation: landscape) {
                max-width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                word-break: break-word;
            }
         
        }
    }
`;

export const IconContainer = styled(Figure)`
    margin: 0;
    display: flex !important;
`;

export const ImageContainer = styled(Figure.Image)`
    margin-right: 0.875rem;
    align-self: flex-start;
`;

export const ContentHeader = styled.h4`
    border-bottom: 1px solid #dddddd;
    width: 100%;
    margin: 1.5rem 1rem;
    padding-bottom: 1rem;
`;

export const StyledTextArea = styled.textarea`
  width: 70%;
  display:block;
  padding: 8px;
  @media (max-width:768px) {
    width:100%;
  }
`;


export const StaffViewHeaderRow = styled(Row)`
margin: 0px;
`;

export const StaffViewContainer = styled.div`
padding: 1rem 2rem;
.label{
    font-weight: 600;
}
`;

export const NameBlock = styled.span`
    padding:8px 8px
    border-radius:50%;
    background-color:#E7F3EB;
    color:#4FB973;
    margin-right: 1rem;
    font-size: ${(props) => (props.fontSize)};
    text-transform: uppercase;
    height: ${(props) => (props.height)};
    width: ${(props) => (props.height)};
    text-align: center;
`;

export const NameTitle = styled.p`
  font-size: 2rem;
  font-weight: 600;
  margin: 0px;
`;

export const InputWithLabel = styled.div`
  display: flex;
  align-items: center;
  .w-200px {
    width: 200px;
  }
`;

export const Pipe = styled.span`
    font-size: 16px;
    padding-left: 10px;
    padding-right: 5px;
    color: #efefef;
`;

export const LabelText = styled.p`
    color: #676767;
    font-family: "Haus Neue";
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 0px;
    padding-right: 5px;
`;

export const SectionBlock = styled(Col)`
  border: 1px solid #bfb6b6 !important;
  border-radius:7px !important;
  padding: 2rem;
  tbody {
    tr:nth-child(odd) {
      background-color: #f9f9f9;
    }
  }
`;

export const SectionHeading = styled.p`
color: #343747;
font-family: "Haus Neue";
font-size: 16px;
font-weight: 600;
margin-bottom: 0px;
display: flex;
justify-content: space-between;
border-bottom: 1px solid #D3D6DD;
`;

export const SubText = styled.p`
font-family: 'Hauora';
font-size: 12px;
font-weight: 600;
`;



export const NavigationContainer = styled.div`

`;

export const NavigationTabSection = styled(Row)`
    color:#676767 !important;
    margin-bottom: 1rem!important;
    margin-right: 0px !important; 
    margin-left: 0px !important;
    line-height: 30px;
`;

export const NavTab = styled(Col)`
    font-size: 1.2rem;
    font-weight: 600 !important;
    text-align: center;
    cursor:pointer;
    border-bottom: 1px solid #676767;
    @media screen and (max-device-width: 1024px) and (orientation: portrait) {
        line-height: 3rem;
        white-space: pre;
    }
    @media screen and (max-device-width:1024px) and (orientation: landscape) {
        line-height: 3rem;
        white-space: pre;
    }
    
    :hover{
        background: #e3ffec !important;
    }  
    &.active {
        color:#42A764 !important;
        border-bottom: 2px solid #42A764;
    }
`;

export const NavHeader = styled.h2`
    padding:0.875rem;
    margin:0;
    @media screen and (max-device-width:4000px) and (orientation: portrait) {
        margin-left: 1.5rem;
    }
    @media screen and (max-device-width:4000px) and (orientation: landscape) {
        margin-left: 1.5rem;
    }
`;

export const InfoCard = styled.div`
  padding: 1rem 1rem;
  font-size: 1.2rem;
  font-weight: bold;
  width: 100%;
  background-color: #F3F9F5;
  border-radius: .5rem;
  margin-bottom: 1rem;
`;

export const IconButton = styled.button`
align-items: center;
background-color: var(--background-color);
border-radius: 6px;
border: 1px #e3dada solid;
height: 40px;
justify-content: center;
padding: 10px;
`;

export const SearchEmailInput = styled.input`
    width: 100%;
    background: white;
    background-repeat: no-repeat;
    background-position: 10px center;
    padding: 9px 9px 9px 9px; /* Adjust the padding as needed */
    font-size: 12px;
    font-family: 'Hauora';
    color: var(--dark-text-color);
    font-weight: 400;
    border: 0.5px solid #b9bcca;
    box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.16);
    border-radius: 3px;
    transition: border 0.15s linear, box-shadow 0.15s linear;
`;